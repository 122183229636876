<template>
  <div class="pageInit activity-stream">
    <h1 class="pageTitle textColor">Activity Stream</h1>
    <div class="lists">
       <el-row :gutter="24" style="margin:0">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4" v-for="(i, index) in imgArr" :key="'item' + index" style="padding:0">
            <div class="item" v-if="i.path" :class="{'rotateY90':i.isRotateYn,'rotateY0':i.isRotateYz}">
              <el-image :src="i.path" lazy ></el-image>
            </div>
          </el-col>
       </el-row>
    </div>

  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";

import axios from "@/api/http";

export default defineComponent({
  name: "ActivityStream",
  components: {
  },
  setup() {
    
    const data = reactive({
      select: '',
      options: [
        {label: "All Tags", value: 1},
      ],
      lists: [
        
      ],
      imgArr: [],
      dataIds: [],
      isReadIds: [],
      blankItem: [],
      bcData:[]
    });
  
    const getData = (index,pageSize,val) => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/getActivityFeeds',
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id',
          index: index,
          pageSize: pageSize
        }
      }).then(res => {
        // console.log('000');
        // console.log(res.data.data,res.data.data.length)
        if(pageSize == 200){
          // cosnole.log('200');
          data.dataIds.pop();
          data.bcData.pop();
          data.isReadIds = [];
          data.imgArr.forEach((i,index)=>{
            data.isReadIds.push(i.id)
          })
          // cosnole.log('200',data.bcData.length,data.dataIds.length);
        }
        res.data.data.forEach((i,index)=>{
          i.isRotateYn = false;
          i.isRotateYz = false;
          i.path = '/files/'+ i.assetUrl;
          i.id = i.id;
          // console.log(index,5555)
          // console.log(data.imgArr.length,5555)
          if(data.imgArr.length < 24){
            // data.imgArr.push(o);
          }

          if(val == 'read'){
            let rs = data.blankItem[Math.floor(Math.random()*data.blankItem.length)];
            // console.log(rs);
            data.imgArr[rs] = i;
            data.blankItem.splice(data.blankItem.indexOf(rs),1);
            data.isReadIds.push(i.id)
          }
          if(data.dataIds.includes(i.id)){

          }else{
           if(data.dataIds.length >= 200){
              data.dataIds.pop();
              data.bcData.pop();
              dataIds.unshift(i.id);
              data.bcData.unshift(i);
            }else{
              data.dataIds.unshift(i.id);
              data.bcData.unshift(i);
              // console.log( data.bcData, data.bcData.length,'6666')
            }
          }
        })
      }).catch(err => {
        console.log(err);
      })
    }

    const getImageArr = () => {
      for(let i=1;i<25;i++){
        let obj = {
          id: i,
          isRotateYn: false,
          isRotateYz: false,
          path:''
        }
        data.imgArr.push(obj);
      }
    }

    const changeImage =() => {
        let isRead = false;
        let blankItemLength = data.blankItem.length;
        let randomBlank = Math.random()*blankItemLength
        let s = data.blankItem[Math.floor(randomBlank)];
        
        let num = Math.floor(24*Math.random());
        if(blankItemLength > 0 ){
          // console.log(s,'111same');
          // console.log(data.imgArr,'333');
          data.imgArr[s].isRotateYz = false;
          data.imgArr[s].isRotateYn = true;
        }else{
          // console.log(num,'9999',data.imgArr[num])
          data.imgArr[num].isRotateYz = false;
          data.imgArr[num].isRotateYn = true;
        }
        setTimeout(function(){
          for(let index in data.bcData){
          // data.bcData.forEach((i,index)=>{
          //   console.log(data.isReadIds,'data.isReadIds',data.isReadIds.length)
            // console.log(data.isReadIds.includes(data.imgArr[index].id));
            if(data.isReadIds.includes(data.bcData[index].id)){
                // isRead = false;
            }else{
              // console.log(blankItemLength,'123456');
              if(blankItemLength > 0 ){
                  // data.imgArr[s] = data.bcData[index];
                  data.imgArr[s]['id'] = data.bcData[index].id;
                  data.imgArr[s]['path'] = data.bcData[index].path;
                  // console.log(data.bcData[index],'3434');
                  // console.log(data.imgArr[s],'3434');
                  data.imgArr[s]['isRotateYz'] = true;
                  data.imgArr[s]['isRotateYn'] = false;
              }else{
                  // data.imgArr[num] = data.bcData[index];
                  data.imgArr[num]['id'] = data.bcData[index].id;
                  data.imgArr[num]['path'] = data.bcData[index].path;
                  // console.log(blankItemLength,'4444',s,'9999999', data.imgArr[num]);
                  // data.imgArr[num][] ="https://cx-app-inpixon.uc.r.appspot.com/files/event/homeLogoBig.png"
                  data.imgArr[num]['isRotateYz'] = true;
                  data.imgArr[num]['isRotateYn'] = false;
              }
              
              data.isReadIds.push(data.bcData[index].id);
              isRead = true;
              return;
            }
          // });
          }
          setTimeout(function(){
            if(isRead == false){
              if(blankItemLength > 0 ){
                  data.imgArr[s]['isRotateYz'] = true;
                  data.imgArr[s]['isRotateYn'] = false;
              }else{
                  // console.log('666',)
                  data.imgArr[num]['isRotateYz'] = true;
                  data.imgArr[num]['isRotateYn'] = false;
              }
            }
          },500)
        },500)
    }
    
    onBeforeMount(() => {
      getImageArr();
      for(let i = 0; i　< 24; i++) {
        data.blankItem.push(i);
      }
      getData(0,24,'read');
      
      // getData(2,24,'notRead');
    });
    onMounted(() => {
      getData(0,200,'notRead');
      setInterval(function(){
        changeImage();
      },3000)

      setInterval(function(){
          //pageIndex = pageIndex + 1;
        getData(0,24,'notRead');
      },30000);

      setInterval(function(){
          //pageIndex = pageIndex + 1;
        getData(0,200,'notRead');
      },60000);
    });
    return {
      ...toRefs(data),
      getData,
      getImageArr,
      changeImage
    };
  },
});
</script>
<style scoped lang='scss'>
.activity-stream {

  .lists {
    padding: 0px 0 20px 0;
    border-top: 1px solid #fff;
  }
  .rotateY90{
    transition: transform 0.5s ease-in-out;
    transform: rotateY(90deg);
  }
  .rotateY0{
    transition: transform 0.5s ease-in-out;
    transform: rotateY(0deg);
  }
}
</style>
